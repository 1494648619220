<template>
  <v-card>
    {{normalizedGraph}}
    <v-card-title class="align-start flex-nowrap">
      <div>
        <p class="text-sm font-weight-semibold mb-2">Faturamento este ano</p>
        <p class="text-no-wrap">
          <span class="text-2xl font-weight-semibold me-1">{{total | moneyBr}}</span>
        </p>
      </div>
      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3">
        <v-icon size="22">{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </v-card-title>

    <!-- Chart -->
    <v-card-text>
      <vue-apex-charts height="180" :options="chartOptions" :series="chartData"></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
  // eslint-disable-next-line object-curly-newline
  import { mdiDotsVertical, mdiChevronUp } from '@mdi/js'


  export default {
    components: {
      VueApexCharts: () => import('vue-apexcharts'),
    },

    props: {
      data: [],
      total: {}
    },

    data() {
      return {

        vuetify: null,

        chartOptions: {
          grid: {
            show: false,
          },

          tooltip: {
           enabled: true,
          },

          chart: {
            type: 'line',
            toolbar: {
              show: false,
            },
          },
          fill: {
            type: 'fill',
            gradient: {
              shade: 'light',
              type: 'horizontal',
              shadeIntensity: 0,
              gradientToColors: undefined,
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 0.4,
              stops: [0, 100, 100],
            },
          },
          stroke: {
            width: 5,
            curve: 'smooth',
            lineCap: 'round',
          },
          colors: [this.$vuetify.theme.currentTheme.primary],
          legend: {
            show: false,
          },
          xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
            min: 0,
          },
        },

        chartData: [
          {
            name: 'Total de vendas',
            data: [],
          },
        ],

        icons: {
          mdiDotsVertical,
          mdiChevronUp,
        },
      }
    },

    computed: {
      normalizedGraph: function () {
        let array = [0]


        if (this.data.length > 0) {
          this.data.map(row => {
            array[row.month - 1] = row.value;
          });

          this.chartData[0].data = array;
        } 
      }
    },

  }
</script>