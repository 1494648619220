<template>
  <v-card>
    <v-card-title class="align-start">
      {{ title }}
    </v-card-title>
    <v-card-subtitle class="align-start">
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-text>
      <v-list class="py-0">
        <v-list-item
            v-for="(guide, index) in guides"
            :key="guide.index"
            class="d-flex align-center px-0"
            :class="index > 0 ? 'mt-4' : ''"
        >
          <v-avatar
              size="40"
              rounded
              :color="paymentMethodVariant(guide.payment_method).color"
              :class="`v-avatar-light-bg ${
              paymentMethodVariant(guide.payment_method).color
            }--text me-3`"
          >
            <v-img
                max-height="20"
                max-width="20"
                contain
                :src="paymentMethodVariant(guide.payment_method).img"
            ></v-img>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap mr-10">
            <div class="me-2">
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ guide.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-xs">
                <a :href="guide.invoice_url" target="_blank"> Link do Boleto </a>
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center">
              <h4 class="text-base font-weight-semibold me-1">
                {{ guide.amount / 100 | moneyBr }}
              </h4>
            </div>
          </div>
          <div>
            <v-chip
                small
                :color="statusVariant(guide.status).color"
                :class="`${statusVariant(guide.status).color}--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              <div>{{ statusVariant(guide.status).text }}</div>
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";

export default {
  name: "CrmLastListOfGuides",
  props: {
    title: {
      type: String,
      default: "Últimas Guias",
    },
    subtitle: {
      type: String,
      default: "Lista das guias fiscais e obrigações do cliente mais recentes",
    },
    guides: {
      type: Array,
      default: () => [
        {
          index: 1,
          name: "Guide 1",
          amount: 1000,
          payment_method: "Money",
          status: "paid",
          invoice_url: "https://google.com",
        },
        {
          index: 2,
          name: "Guide 2",
          amount: 2000,
          payment_method: "Card",
          status: "paid",
          invoice_url: "https://google.com",
        },
        {
          index: 3,
          name: "Guide 3",
          amount: 3000,
          payment_method: "Pix",
          status: "paid",
          invoice_url: "https://google.com",
        },
        {
          index: 4,
          name: "Guide 4",
          amount: 4000,
          payment_method: "Money",
          status: "paid",
          invoice_url: "https://google.com",
        },
        {
          index: 5,
          name: "Guide 5",
          amount: 5000,
          payment_method: "Card",
          status: "paid",
          invoice_url: "https://google.com",
        },
      ],
    }
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },
  methods: {
    paymentMethodVariant(paymentMethod) {
      const paymentMethods = {
        Money: {
          color: "success",
          img: require("@/assets/images/pages/cash02.png"),
        },
        Card: {
          color: "warning",
          img: require("@/assets/images/pages/atm-card.png"),
        },
        Pix: {
          color: "info",
          img: require("@/assets/images/pages/pix.png"),
        },
      };

      return paymentMethods[paymentMethod] || {
        color: "info",
        img: require("@/assets/images/pages/cash02.png"),
      };

    },

    statusVariant(status) {
      const statusList = {
        draft: { color: "secondary", text: "Rascunho" },
        paid: { color: "success", text: "Faturado" },
        cancelled: { color: "error", text: "cancelado" },
        pendding: { color: "warning", text: "Pendente" },
      };

      return statusList[status] || { color: "secondary", text: "Rascunho" };
    },
  },
};
</script>
