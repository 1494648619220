<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Estatisticas</span>
    </v-card-title>

    <v-card-subtitle class="mb-7">
      <span class="font-weight-semibold text--primary me-1">Vendas por plataforma</span>
      <span>{{ customDate ? "🧐 Datas em Questão" : "😎 Este mês"}}</span>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          sm="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation(data.title).color"
            rounded
            class="elevation-1"
          >
            <v-img
              dark
              color="white"
              :src="resolveStatisticsIconVariation(data.title).icon"
            ></v-img>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">{{ data.title }}</p>
            <h3 class="text-md font-weight-bold">{{ data.total / 100 | money}}</h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

  export default {

    props: {
      statisticsData: [],
      customDate:{
        default: false
      }
    },

    methods: {
      resolveStatisticsIconVariation(data) {
        if (data === "Braip") return { icon: require('@/assets/images/svg/braip-white.svg'), color: "braip" };
        if (data === "Hotmart") return { icon: require('@/assets/images/svg/hotmart-white.svg'), color: "hotmart" };
        if (data === "Eduzz") return { icon: require('@/assets/images/svg/eduzz-white.svg'), color: "eduzz" };
        if (data === "Monetizze") return { icon: require('@/assets/images/svg/monetizze-white.svg'), color: "monetizze" };

        return { icon: require('@/assets/images/svg/trendingUp-white.svg'), color: "success" };
      }
    }
  }
</script>
