<template>
  <div> 
    <div class="mb-8">
      <h1>Dashboard</h1>
      <v-row class="d-flex align-center">
          <div class="col-8">
            <div v-if="dueDate">
              <span v-if="dueDate[0] == dueDate[1]">
                No dia {{ dueDate[0] | readerDate }}
              </span>
              <span v-else>
                Do {{ dueDate[0] | readerDate }} até {{ dueDate[1] | readerDate }}
              </span>
            </div>
            <span v-else>
              Hoje; {{ dueDate | readerDate }}
            </span>
          </div>
          <div class="col d-flex">
            <div :class="{'col-8':dueDate, 'col':!dueDate}">
              <date-picker ref="dueDatePickerRef" range v-model="dueDate"></date-picker>
            </div>
            <div class="col" v-if="dueDate">
              <v-btn
                color="secondary"
                outlined
                @click="clearDueDate"
              >
                Hoje
              </v-btn>
            </div>
          </div>
      </v-row>
      <v-divider></v-divider>
    </div>
    <v-row :style="{opacity: loading ? 0.1 : 1}" style="transition: 500ms linear;">

      <!-- Faturamento Hoje -->
      <v-col cols="12" md="3" sm="6" order="1" class="align-self-end">
        <statistics-card-with-images
          :avatar="revenueToday.avatar"
          :avatar-width="revenueToday.avatarWidth"
          :change="revenueToday.change"
          :chip-color="revenueToday.chipColor"
          :chip-text="revenueToday.chipText"
          :statistics="revenueToday.statistics | moneyBr"
          :stat-title="revenueToday.statTitle"
        ></statistics-card-with-images>
      </v-col>
      
      <!-- Faturamento Mes - Params -->
      <v-col cols="12" md="3" sm="6" order="2" class="align-self-end">
        <statistics-card-with-images
          :avatar="revenueMonth.avatar"
          :avatar-width="revenueMonth.avatarWidth"
          :change="revenueMonth.change"
          :chip-color="revenueMonth.chipColor"
          :chip-text=" !dueDate ? 'Mes Atual' : 'Datas em Questão'"
          :statistics="revenueMonth.statistics | moneyBr"
          :stat-title="revenueMonth.statTitle"
        ></statistics-card-with-images>
      </v-col>

      <!-- Estatisticas mes - params -->
      <v-col cols="12" md="6" sm="12" order="5" order-md="3">
        <crm-statistics-card :statisticsData="platformsValues" :customDate="dueDate"></crm-statistics-card>
      </v-col>

      <!-- Faturamento Este Ano -->
      <v-col cols="12" md="3" sm="6" order="3" order-md="4">
        <crm-total-sales :data="revenueYear" :total="yearTotal"></crm-total-sales>
      </v-col>

      <!-- Financeiro -->
      <v-col cols="12" md="3" sm="6" order="4" order-md="5">
        <crm-revenue-report :data="revenue"></crm-revenue-report>
    </v-col>

      <!-- Ticket Medio -->
      <v-col cols="12" md="6" sm="12" order="6" >
        <crm-sales-overview :data="ticket"></crm-sales-overview>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <crm-last-list-of-guides :guides="guides"/>
      </v-col>

      <v-col cols="12" sm="6">
        <crm-most-profitable-city :data="topCities"></crm-most-profitable-city>
      </v-col>

      <v-col cols="12" sm="6" class="pt-0">
        <v-col>
          <!-- vendas -->
          <statistics-card-area-chart
            :stat-title="salesByMonth.statTitle"
            :statistics="salesByMonth.statistics"
            :chart-series="salesByMonth.series"
            chart-height="100"
          ></statistics-card-area-chart>
        </v-col>
        <v-col>
          <!-- notas emitidas -->
          <statistics-card-vertical
            :change="invoiceCount.change"
            :color="invoiceCount.color"
            :icon="invoiceCount.icon"
            :statistics="invoiceCount.statistics"
            :stat-title="invoiceCount.statTitle"
            :subtitle="invoiceCount.subtitle"
          ></statistics-card-vertical>
        </v-col>

        <v-col>
          <crm-activity-timeline :data="taskList"></crm-activity-timeline>
        </v-col>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import CrmCongratulations from './CrmCongratulations.vue'
  import StatisticsCardWithImages from '@core/components/statistics-card/StatisticsCardWithImages.vue'
  import StatisticsCardAreaChart from '@core/components/statistics-card/StatisticsCardAreaChart.vue'
  import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'
  // icons
  import { mdiFileDocumentOutline, mdiCurrencyUsd } from '@mdi/js'
  // demos
  import CrmLastListOfGuides from './CrmLastListOfGuides.vue'
  import CrmStatisticsCard from './CrmStatisticsCard.vue'
  import CrmTotalSales from './CrmTotalSales.vue'
  import CrmRevenueReport from './CrmRevenueReport.vue'
  import CrmSalesOverview from './CrmSalesOverview.vue'
  import CrmActivityTimeline from './CrmActivityTimeline.vue'
  import CrmWeeklySales from './CrmWeeklySales.vue'
  import CrmCardUpgradePlan from './CrmCardUpgradePlan.vue'
  import CrmCardMeetingSchedule from './CrmCardMeetingSchedule.vue'
  import CrmCardDeveloperMeetup from './CrmCardDeveloperMeetup.vue'
  import CrmMostProfitableCity from './CrmMostProfitableCity.vue'
  //Notifications 
  import Echo from "laravel-echo";
  import pusher from "pusher-js";
  import Filter from '@/util/Filters';
  import datePicker from '@/components/app/ui/datePicker.vue'

  export default {
    components: {
      StatisticsCardWithImages,
      StatisticsCardAreaChart,
      StatisticsCardVertical,
      CrmStatisticsCard,
      CrmTotalSales,
      CrmRevenueReport,
      CrmSalesOverview,
      CrmActivityTimeline,
      CrmWeeklySales,
      CrmCardUpgradePlan,
      CrmCardMeetingSchedule,
      CrmCardDeveloperMeetup,
      CrmCongratulations,
      datePicker,
      CrmLastListOfGuides,
      CrmMostProfitableCity,
      datePicker
    },

    data() {
      return {
        dialog: false,
        dueDate: null,
        loading: null,

        transactionsOptions: {
          statTitle: 'Empresas',
          icon: mdiFileDocumentOutline,
          color: 'info',
          subtitle: 'Empresas ativas',
          statistics: '1.2k',
          change: '',
        },

        revenueOptions: {
          statTitle: 'Usuários',
          icon: mdiCurrencyUsd,
          color: 'success',
          subtitle: 'Usuários logados',
          statistics: '23',
        },

        revenueToday: {
          statTitle: 'Faturamento',
          statistics: 0.00,
          chipText: 'Hoje',
          chipColor: 'primary',
          avatar: require('@/assets/images/avatars/9.png'),
          avatarWidth: '111',
        },

        revenueMonth: {
          statTitle: 'Faturamento',
          statistics: 0.00,
          chipColor: 'secondary',
          avatar: require('@/assets/images/avatars/10.png'),
          avatarWidth: '86',
        },

        salesByMonth: {
          statTitle: 'Vendas neste Ano',
          statistics: "---",
          series: [{data:[0]}],
        },
        // vertical card options
        invoiceCount: {
          statTitle: 'Notas Fiscais Emitidas',
          icon: mdiFileDocumentOutline,
          color: 'primary',
          subtitle: 'Neste Ano',
          statistics: '862',
        },

        revenueYear: [],
        topCities:[],
        yearTotal: {},
        platformsValues: [],
        revenue: [],
        ticket: {
          ticketAvarage: [],
          ticketAvarageTotal: 0
        },
        guides:[],
        taskList: []
      }
    },

    created() {
      const token = JSON.parse(localStorage.getItem('token'));
      const userId = this.$store.state.auth.user.user.id;

      if (token) {
        const echo = new Echo({
          broadcaster: "pusher",
          key: "6613b25106004176ac5a",
          cluster: "us2",
          forceTLS: true,
          authEndpoint: (process.env.NODE_ENV === 'production') ? "https://api.ezcont.com.br/broadcasting/auth" : "http://ezcont.test/broadcasting/auth",
          auth: {
            headers: {
              Authorization: `Bearer ${token.access_token}`,
            },
          },
        });

        echo.private(`App.Models.User.${userId}`).notification((notification) => {
          this.$store.commit('notification/setNotification', notification);
        });
      }

      this.getData()
    },

    methods: {
      getData() {
        this.loading = true
        this.$http.index('dashboard', {date: this.dueDate}).then(response => {
          this.revenueToday.statistics = response.billToday.total / 100
          this.revenueMonth.statistics = response.billMonth.total / 100
          this.revenueYear = response.yearTotalByMonth
          this.yearTotal = response.yearOrderTotal.total / 100
          this.platformsValues = response.orderByPlatform
          this.revenue = response.revenue
          this.ticket = response.ticket
          this.taskList = response.nextTask
          this.invoiceCount.statistics = response.invoicesCount.toString()
          this.topCities = response.topCities

          this.guides = response.guides
          this.salesByMonth.series = [{data: Object.values(response.totalSales).map(sale => sale.length)}]
          this.salesByMonth.statistics = this.salesByMonth.series[0].data.reduce((a, b) => a + b, 0).toString() + ' vendas'

          this.loading = false
        });
      },

      clearDueDate(){
        this.$refs.dueDatePickerRef.reset();
        this.dueDate = null;
      },
    },

    watch:{
      dueDate() {
        this.getData();
      }
    }
  }
</script>
