<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Proximos Objetivos</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="mt-n2 me-n3"
      >
        <v-icon size="22">
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-timeline
        dense
        class="timeline-custom-dense timeline-custom-dots"
      >
        <v-timeline-item
          v-for="task in data"
          :key="task.title"
          small
          :color="task.category"
        >
          
          <div class="d-flex justify-space-between align-center flex-wrap">
            <h4 class="font-weight-semibold me-1">
              {{ task.name }}
            </h4>
            <small class="text-no-wrap">{{ task.start | readerDate  }}</small>
          </div>
          
          <p class="mb-0">
            {{ task.description }}
          </p>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import Filter from '@/util/Filters';

export default {
  props:{
    data: []
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },
    }
  },
}
</script>