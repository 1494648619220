<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ title }}</span>
    </v-card-title>

    <v-card-subtitle>
      <span class="font-weight-semibold text--primary me-1"> {{ subtitle }} </span>
    </v-card-subtitle>

    <v-card-text>
      <vue-apex-charts
          height="250"
          :options="options"
          :series="series"
      ></vue-apex-charts>
    </v-card-text>
  </v-card>
</template>

<script>
import {addAlpha, getVuetify} from "@core/utils";

export default {
  name: 'CrmMostProfitableCity',
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },
  props: {
    title: {
      type: String,
      default: 'Cidades Mais Rentáveis',
    },
    subtitle: {
      type: String,
      default: 'Top 5',
    },
    data: {
      type: Array,
      default: () => ([
        {
          name: 'City one',
          value: 234,
        },
        {
          name: 'City two',
          value: 400,
        },
        {
          name: 'City three',
          value: 500,
        },
        {
          name: 'City four',
          value: 110,
        },
        {
          name: "City five",
          value: 340,
        },
      ]),
    },
  },
  setup() {
    const $vuetify = getVuetify()
    const chartOptions = {
      colors: [
        addAlpha($vuetify.theme.currentTheme.primary),
      ],
      chart: {
        type: 'bar',
        toolbar: {
          show: false ,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          columnWidth: '60%',
          distributed: true,
          borderRadius: 4,
          startingShape: 'rounded',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: ['City one', 'City two', 'City three', 'City four', 'City five'],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        tickPlacement: 'on',
        labels: {
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: false,
      },
      grid: {
        show: true,
        padding: {
          top: -20,
          left: -10,
          right: 10,
        },
      },
    }

    const chartData = [
      {
        data: [300, 70, 60, 50, 40 ],
      },
    ]

    return {
      chartOptions,
      chartData
    }
  },
  computed: {
    options() {
      if (!this.data || this.data?.length === 0) return this.chartOptions

      let xaxis = this.chartOptions.xaxis
      xaxis.categories = this.data
            .sort((currentItem, nextItem) => nextItem.value - currentItem.value)
            .map(item => item.name)

      return {
        ...this.chartOptions,
        xaxis
      }
    },
    series() {
      if (!this.data || this.data?.length === 0) return this.chartData

      return [
          {
            data: this.data
                .map(item => item.value)
                .sort((currentItem, nextItem) => nextItem.value - currentItem.value)
          },
      ]
    },
  },
}

</script>