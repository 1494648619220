<template>
  <v-card>
    {{normalizedGraph}}
    <v-card-title>
      <span class="text-sm font-weight-semibold">Financeiro</span>
      <v-spacer></v-spacer>
      <v-chip class="v-chip-light-bg secondary--text font-weight-semibold" small>Este ano</v-chip>
    </v-card-title>

    <!-- Chart -->
    <vue-apex-charts height="250" :options="chartOptions" :series="chartData"></vue-apex-charts>
  </v-card>
</template>

<script>
  export default {
    components: {
      VueApexCharts: () => import('vue-apexcharts'),
    },

    props:{
      data:[]
    },

    data() {
      // const $vuetify = getVuetify()
      return {
        chartOptions: {
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          grid: {
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            labels: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            markers: {
              radius: 15,
            },
          },
          stroke: {
            width: 3,
            colors: ['#fff'],
          },
          dataLabels: {
            enabled: false,
          },
          colors: [this.$vuetify.theme.currentTheme.success, this.$vuetify.theme.currentTheme.secondary],
          plotOptions: {
            bar: {
              columnWidth: '80%',
              borderRadius: 6,
              startingShape: 'rounded',
              endingShape: 'rounded',
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          }
        },

        chartData:[
          {
            name: 'Receitas',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: 'Despesas',
            data: [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0],
          },
        ]
      }

    },

    
    computed: {
      normalizedGraph: function (){
        let incomeArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let outcomeArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        
        if (this.data.income) {
          Object.keys(this.data.income).forEach(element => {
            incomeArray[parseInt(element)-1] = this.data.income[element]
          });
          Object.keys(this.data.outcome).forEach(element => {
            outcomeArray[parseInt(element)-1] = this.data.outcome[element] * -1
          });

          this.chartData = [
              {
                name: 'Receitas',
                data: incomeArray
              },
              {
                name: 'Despesas',
                data: outcomeArray
              },
            ]
        }

      },
    }    
  }
</script>