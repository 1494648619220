<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Ticket Medio</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <vue-apex-charts
            id="chart-sales-overview"
            :options="chartOptions"
            :series="chartData"
            class="h-full d-flex align-center"
          ></vue-apex-charts>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex flex-column justify-center"
        >
          <div class="d-flex align-center">
            <v-avatar
              class="v-avatar-light-bg primary--text"
              rounded
              size="40"
            >
              <v-icon
                size="30"
                color="primary"
              >
                {{ icons.mdiCurrencyUsd }}
              </v-icon>
            </v-avatar>
            <div class="ms-4 d-flex flex-column">
              <p class="text--primary mb-0 text-base">
                Ticket Medio Geral
              </p>
              <span class="text--primary font-weight-semibold text-xl">{{ data.ticketAvarageTotal.ticket / 100 | moneyBr }}</span>
            </div>
          </div>

          <v-divider class="my-6"></v-divider>

          <table class="sales-overview-stats-table">
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot d-inline-block rounded-circle me-2" style="background-color: #F04E23"></div>
                  <span>Hotmart</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ chartData[0] / 100 | moneyBr }}</span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot d-inline-block rounded-circle me-2" style="background-color: #8229FF"></div>
                  <span>Braip</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ chartData[1] / 100 | moneyBr }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="mb-0">
                  <div class="stats-dot d-inline-block rounded-circle me-2" style="background-color: #FFCD33"></div>
                  <span>Eduzz</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ chartData[2] / 100 | moneyBr }}</span>
              </td>
              <td>
                <div class="mb-0">
                  <div class="stats-dot d-inline-block rounded-circle me-2" style="background-color: #2580BC"></div>
                  <span>Monetizze</span>
                </div>
                <span class="text-base text--primary font-weight-semibold ms-4">{{ chartData[3] / 100 | moneyBr }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCurrencyUsd } from '@mdi/js'
import { getVuetify } from '@core/utils'
import Filter, { moneyBr } from "@/util/Filters";

export default {
  components: {
    VueApexCharts: () => import('vue-apexcharts'),
  },

  props:{
    data: {}
  },

  setup() {
    const $vuetify = getVuetify()

    const chartOptions = {
      labels: ['Hotmart', 'Braip', 'Eduzz', 'Monetizze', 'Avulsas'],
      tooltip: {
        enabled: false
      },
      colors: [
        '#F04E23',
        '#8229FF',
        '#FFCD33',
        '#2580BC',
        $vuetify.theme.currentTheme.success
      ],
      chart: {
        type: 'donut',
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        pie: {
          donut: {
            tooltip: {
              enabled: false,
            },
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '2.125rem',
                fontWeight: 600,

                offsetY: -15,
                formatter(value) {
                  return `${moneyBr(value / 100)}`
                },
              },
              total: {
                show: true,
                label: 'Vendas',
                color: 'rgba(94, 86, 105, 0.68)',
                formatter(value) {
                  return `${moneyBr(value.globals.seriesTotals.reduce((total, num) => total + num) / 100)}`
                },
              },
            },
          },
        },
      },
    }
    return {
      chartOptions,
      icons: {
        mdiCurrencyUsd,
      },
    }
  },

  computed: {
    chartData(){
      let data = {'Hotmart':0,'Braip':0, 'Eduzz':0, 'Monetizze':0, '*': 0}
      this.data.ticketAvarage.forEach((e) => {
        if (e['title'] in data) {
          data[e['title']] = parseInt(e['total'])
        }else{
          data['*'] = parseInt(e['total'])
        }
      });
      return Object.values(data)
    }
  }

}
</script>

<style lang="scss">
#chart-sales-overview {
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
      }
      &.apexcharts-datalabel-label {
        font-size: 1rem;
      }
    }
  }
}

.sales-overview-stats-table {
  width: 100%;
  td {
    padding-bottom: 1rem;
  }

  .stats-dot {
    padding: 0.33rem;
  }

  // Set opacity of dots
  tr {
    &:nth-of-type(1) {
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.7;
        }
      }
    }
    &:nth-of-type(2) {
      td:nth-of-type(1) {
        .stats-dot {
          opacity: 0.5;
        }
      }
      td:nth-of-type(2) {
        .stats-dot {
          opacity: 0.15;
        }
      }
    }
  }
}
</style>